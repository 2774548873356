import { createSlice } from '@reduxjs/toolkit';
import { DataState } from 'redux/data/types';
import {
  postIdentity,
  getApp,
  checkIfHasLimit,
  getAppProgress,
  otpConfirm,
  otpSend,
  getAppConsent,
  postApp,
  getOffer,
  postAppConsent,
  getAppConsentValues,
  postKYCStatus,
  acceptOffer,
  putApp,
  postStatement,
  getLimitInfo,
  checkPeselStatus,
} from 'redux/data/thunk';

import useNotification from 'hooks/useNotification';
import { getErrorMsg } from 'helpers/getErrorMessages';
import { ProductTypesEnum } from 'types/enums';

const initialState: DataState = {
  isLoading: false,
  isInitLoading: true,
  isStarted: false,
  app: null,
  hasLimit: null,
  identity: null,
  offer: null,
  consents: [],
  consentsValues: [],
  limit: [],
  appProgressTemp: null,
  peselStatus: null,
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetAppData: (state) => {
      state.app = null;
      state.appProgressTemp = null;
    },
    start: (state) => {
      state.isInitLoading = true;
      state.isStarted = true;
    },
    finish: (state) => {
      state.isInitLoading = false;
    },
  },
  extraReducers: (builder) => {
    // IDENTITY
    builder.addCase(postIdentity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postIdentity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.identity = {
        pesel: action.payload.data.pesel ?? '',
        birthCountry: action.payload.data.birthCountry,
        citizenship: action.payload.data.citizenship,
        idCard: action.payload.data.identityDocuments?.[0]?.number ?? '',
      };
    });
    builder.addCase(postIdentity.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Nie mogliśmy zapisać Twoich danych. ${getErrorMsg(
            action.payload?.errors
          )}`
        ),
        10
      );
    });

    // GET APP
    builder.addCase(getApp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.app = action.payload.data;
    });
    builder.addCase(getApp.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas pobierania danych ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    // POST APP
    builder.addCase(postApp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.app = action.payload.data;
    });
    builder.addCase(postApp.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas wysyłania wniosku. ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    //PUT APP
    builder.addCase(putApp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(putApp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.app = action.payload.data;
    });
    builder.addCase(putApp.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas aktualizacji wniosku ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    // POST STATEMENT
    builder.addCase(postStatement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postStatement.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postStatement.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas wysyłania zgód ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    // GET OFFER
    builder.addCase(getOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.offer = action.payload.data[0];
      state.isLoading = false;
    });
    builder.addCase(getOffer.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas pobierania oferty. ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    // HAS LIMIT
    builder.addCase(checkIfHasLimit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkIfHasLimit.fulfilled, (state, action) => {
      const isProduct =
        +action.meta.arg.reqId === ProductTypesEnum.limitProduct;
      state.isLoading = false;
      state.hasLimit = isProduct && action.payload.data.exists;
    });
    builder.addCase(checkIfHasLimit.rejected, (state) => {
      state.isLoading = false;
    });

    // APP PROGRESS
    builder.addCase(getAppProgress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAppProgress.fulfilled, (state, action) => {
      state.appProgressTemp = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getAppProgress.rejected, (state) => {
      state.isLoading = false;
    });

    // GET CONSENTS
    builder.addCase(getAppConsent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAppConsent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.consents = action.payload.data;
    });
    builder.addCase(getAppConsent.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas pobierania zgód ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    //ACCEPT OFFER
    builder.addCase(acceptOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(acceptOffer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(acceptOffer.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas wysyłania danych ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });
    // GET CONSENTS VALUES
    builder.addCase(getAppConsentValues.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAppConsentValues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.consentsValues = action.payload.data;
    });
    builder.addCase(getAppConsentValues.rejected, (state, action) => {
      state.isLoading = false;
    });

    // POST CONSENTS
    builder.addCase(postAppConsent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postAppConsent.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postAppConsent.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Wystąpił błąd podczas wysyłania danych ${getErrorMsg(
            action.payload?.errors
          )}`
        )
      );
    });

    //OTP SEND
    builder.addCase(otpSend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(otpSend.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(otpSend.rejected, (state) => {
      const notify = useNotification();
      notify(
        getErrorMsg('Wystąpił błąd z wysłaniem kodu weryfikacyjnego na telefon')
      );
      state.isLoading = false;
    });

    //OTP CONFIRM
    builder.addCase(otpConfirm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(otpConfirm.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(otpConfirm.rejected, (state) => {
      const notify = useNotification();
      notify(
        getErrorMsg('Wystąpił bład z potwierdzeniem kodu weryfikacyjnego')
      );
      state.isLoading = false;
    });

    // POST KYC STATUS
    builder.addCase(postKYCStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postKYCStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postKYCStatus.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        getErrorMsg(
          `Posiadasz już aktywną kartę. Skontaktuj się z nami jeżeli szukasz dodatkowego finansowania`
        )
      );
    });

    // GET LIMIT
    builder.addCase(getLimitInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLimitInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.limit = action.payload.data;
    });
    builder.addCase(getLimitInfo.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(getErrorMsg(`Wystąpił błąd podczas pobierania danych o limicie`));
    });

    // Check pesel status
    builder.addCase(checkPeselStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkPeselStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.peselStatus = action.payload.data.peselStatus;
    });
    builder.addCase(checkPeselStatus.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(getErrorMsg(`Wystąpił błąd podczas pobierania danych o Peselu`));
    });
  },
});

export const { start, finish, resetAppData } = dataSlice.actions;
