import { errorMessages } from 'validation/messages';
import z from 'zod';

export const identitySchemaMobileWebFlow = z.object({
  birthCountry: z.object(
    {
      label: z.string(),
      value: z.string(),
    },
    { required_error: errorMessages.chooseCountry }
  ),
  citizenship: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: errorMessages.chooseCitizenship }
    )
    .refine(({ value }) => value === 'PL', {
      message: errorMessages.polishCitizenshipIsRequired,
    }),
  politicalStatus: z
    .string()
    .min(1, { message: errorMessages.politicalStatus }),
});

export type identitySchemaMobileWebFlowFtype = z.infer<
  typeof identitySchemaMobileWebFlow
>;
