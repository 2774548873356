import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'components/Button';
import { FormControl } from 'components/FormControl';
import {
  FloatingLabelSelectInput,
  FloatingLabelTextInput,
  Radio,
  RadioWrapper,
} from 'components/FormControlComponents';
import { countries } from 'helpers/countries';
import useConsents from 'hooks/useConsents';
import { useKeyboardCloseOnUnmount } from 'hooks/useKeyboardCloseOnUnmount';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { resetAppData } from 'redux/data/reducer';
import { postIdentity } from 'redux/data/thunk';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { routes } from 'routes';
import { ConsentTypeEnum, identityDocumentEnum } from 'types/enums';
import {
  IdentitySchema,
  identitySchema,
  identitySchemaMobileWebFlow,
} from 'validation';
import 'views/Identity/styles.scss';
import LoadingView from 'views/Loading';

const classNamePrefix: 'identity' = 'identity';

const IdentityView: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useKeyboardCloseOnUnmount();

  const { pepConsents, pepConsentsValues } = useConsents();
  const questionnaire = useAppSelector(
    (state) => state.Data.appProgressTemp?.registrationSteps?.questionnaire
  );
  const appToken = useAppSelector(
    (state) => state.Data.app?.applicationDetails.token
  );

  const { isLoading, identity } = useAppSelector((state) => state.Data);
  const { userData, isLoading: userLoading } = useAppSelector(
    (state) => state.Account
  );

  const ConutriesSelectOptions = countries.map((country) => {
    return {
      value: country.code,
      label: country.name_pl,
    };
  });

  const defaultValues = {
    birthCountry: ConutriesSelectOptions.find(({ value }) => value === 'PL')!,
    citizenship: ConutriesSelectOptions.find(({ value }) => value === 'PL')!,
    politicalStatus: '',
  };

  const methods = useForm<IdentitySchema>({
    resolver: zodResolver(identitySchema),
    defaultValues,
  });
  const methodsMobileWeb = useForm<IdentitySchema>({
    resolver: zodResolver(identitySchemaMobileWebFlow),
    defaultValues,
  });

  const { setValue, reset } = methods;

  const onSubmit = ({ idCard, ...data }: IdentitySchema) => {
    const selectedConsent = pepConsents.find(
      (consent) => consent.id === +data.politicalStatus
    );
    const isPep =
      selectedConsent && selectedConsent.type === ConsentTypeEnum.pep;

    if (questionnaire) {
      const sendData = {
        birthCountry: data.birthCountry.value,
        citizenship: data.citizenship.value,
        consents: pepConsents.map(({ id }) => ({
          id,
          checked: id === selectedConsent?.id,
        })),
        applicationToken: appToken,
      };
      dispatch(postIdentity(sendData)).then((action) => {
        if (action.payload?.status === 200) {
          reset();
          if (isPep) {
            dispatch(resetAppData());
            navigate(routes.contact);
          } else if (questionnaire) {
            navigate(routes.consents);
          }
        }
      });
      return;
    }

    const sendData = {
      ...data,
      birthCountry: data.birthCountry.value,
      citizenship: data.citizenship.value,
      identityDocuments: [
        {
          type: identityDocumentEnum.idCard,
          number: idCard.replace(/\s+/g, '').toUpperCase(),
        },
      ],
      consents: pepConsents.map(({ id }) => ({
        id,
        checked: id === selectedConsent?.id,
      })),
    };
    dispatch(postIdentity(sendData)).then((action) => {
      if (action.payload?.status === 200) {
        reset();
        if (isPep) {
          dispatch(resetAppData());
          navigate(routes.contact);
        } else {
          navigate(routes.limit);
        }
      }
    });
  };

  useEffect(() => {
    if (identity || userData) {
      const userBirthCountry = ConutriesSelectOptions.find(
        (country) => country.value === userData?.client?.birthCountry
      );
      const userCitizenship = ConutriesSelectOptions.find(
        (country) => country.value === userData?.client?.citizenship
      );

      const defaultCountry = ConutriesSelectOptions.find(
        ({ value }) => value === 'PL'
      )!;

      const defaultData = identity
        ? {
            ...identity,
            birthCountry: ConutriesSelectOptions.find(
              ({ value }) => value === identity.birthCountry
            )!,
            citizenship: ConutriesSelectOptions.find(
              ({ value }) => value === identity.citizenship
            )!,
          }
        : {
            birthCountry: userBirthCountry ?? defaultCountry,
            citizenship: userCitizenship ?? defaultCountry,
            pesel: userData?.client?.pesel ?? '',
            idCard: userData?.client?.idCard ?? '',
            politicalStatus: pepConsentsValues.find(({ checked }) => !!checked)
              ?.id,
          };
      setValue('pesel', defaultData.pesel);
      setValue('idCard', defaultData.idCard);
      setValue('birthCountry', defaultData.birthCountry);
      setValue('citizenship', defaultData.citizenship);
    }
  }, [identity, userData]);

  if (userLoading) {
    return <LoadingView text="Trwa wczytywanie danych" />;
  }

  return (
    <div className={classNamePrefix} ref={ref}>
      <div className={`${classNamePrefix}__inner`}>
        <p className={`${classNamePrefix}__info`}>
          Zweryfikuj swoją tożsamość:
        </p>

        {questionnaire ? (
          <FormControl
            submitHandler={onSubmit}
            methodsContext={methodsMobileWeb}
          >
            <FloatingLabelSelectInput
              name="birthCountry"
              label="Kraj urodzenia"
              options={ConutriesSelectOptions}
            />
            <FloatingLabelSelectInput
              name="citizenship"
              label="Obywatelstwo"
              options={ConutriesSelectOptions}
            />

            <div className="required-group">
              <RadioWrapper
                name="politicalStatus"
                description="Niniejszym, będąc świadomy odpowiedzialności karnej za złożenie fałszywego oświadczenia, oświadczam, że (prosimy zaznaczyć odpowiednią pozycję)"
              >
                {pepConsents.map(({ id, required, label }) => (
                  <Radio
                    isRequired={required}
                    key={id}
                    name="politicalStatus"
                    content={label}
                    value={id}
                    id={id.toString()}
                  />
                ))}
              </RadioWrapper>
            </div>
            <div className={`${classNamePrefix}__button`}>
              <Button loading={isLoading} styleType="primary">
                Potwierdź
              </Button>
            </div>
          </FormControl>
        ) : (
          <FormControl submitHandler={onSubmit} methodsContext={methods}>
            <FloatingLabelTextInput name="pesel" label="PESEL" id="pesel" />
            <FloatingLabelSelectInput
              name="birthCountry"
              label="Kraj urodzenia"
              options={ConutriesSelectOptions}
            />
            <FloatingLabelSelectInput
              name="citizenship"
              label="Obywatelstwo"
              options={ConutriesSelectOptions}
            />
            <FloatingLabelTextInput
              name="idCard"
              label="Seria i numer dowodu osobistego"
              id="idCard"
            />
            <div className="required-group">
              <RadioWrapper
                name="politicalStatus"
                description="Niniejszym, będąc świadomy odpowiedzialności karnej za złożenie fałszywego oświadczenia, oświadczam, że (prosimy zaznaczyć odpowiednią pozycję)"
              >
                {pepConsents.map(({ id, required, label }) => (
                  <Radio
                    isRequired={required}
                    key={id}
                    name="politicalStatus"
                    content={label}
                    value={id}
                    id={id.toString()}
                  />
                ))}
              </RadioWrapper>
            </div>
            <div className={`${classNamePrefix}__button`}>
              <Button loading={isLoading} styleType="primary">
                Potwierdź
              </Button>
            </div>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default IdentityView;
